import React from 'react';
import {
    Box,
    Container,
    VStack,
    Heading,
    Text,
    Image,
    Button,
    SimpleGrid,
    Flex,
    useTheme,
    useColorMode,
    useClipboard,
    Icon,
    Divider
} from '@chakra-ui/react';
import {
    FaShareAlt, FaHotel,
    FaUtensils,
    FaSubway
} from 'react-icons/fa';



const Activites = ( {blogData} ) => {
    const { colorMode } = useColorMode();
    const theme = useTheme();
    const { hasCopied, onCopy } = useClipboard(window.location.href);

    // Fonction de partage
    const handleShare = () => {
        if (navigator.share) {
            navigator.share({
                title: blogData.title,
                text: `Découvrez mon voyage à ${blogData.place} par ${blogData.author}`,
                url: window.location.href
            }).catch(console.error);
        } else {
            onCopy();
        }
    };

    return (
        <Box
            bg={colorMode === 'dark' ? 'dark_secondary.bg' : 'light_secondary.bg'}
            color={colorMode === 'dark' ? 'dark_secondary.color' : 'light_secondary.color'}
            minHeight="100vh"
            pt={{ base: 28, md: 40 }}
            pb={{ base: 16, md: 32 }}
            position="relative"
        >

            <Container maxW="container.xl">
                <VStack spacing={8} align="stretch">
                    {/* Hero Section */}
                    <Box textAlign="center" mb={{ base: 4, md: 10 }}>
                        <Heading
                            as="h1"
                            size="2xl"
                            mb={4}
                            color={colorMode === 'dark' ? 'dark_tertiary.bg' : 'light_tertiary.bg'}
                        >
                            {blogData.title}
                        </Heading>
                        {/* Bouton de partage */}
                        <Button
                            aria-label="Partager le blog"
                            position="relative"
                            mt={6}
                            zIndex={10}
                            onClick={handleShare}
                            bg={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                            color={colorMode === 'dark' ? theme.colors.dark_tertiary.color : theme.colors.light_tertiary.color}
                            _hover={{
                                bg: colorMode === 'dark' ? theme.colors.dark.bg : theme.colors.light.bg,
                                transform: 'translateY(-2px)',
                                boxShadow: 'lg'
                            }}
                            variant="full"
                        >
                            <Icon as={FaShareAlt} mr={2} />
                            {hasCopied ? 'Lien copié !' : 'Partager'}
                        </Button>
                    </Box>


                    {/* Hero Image */}
                    <Box borderRadius="xl" overflow="hidden" mb={{ base: 8, md: 16 }}>
                        <Image
                            src={blogData.heroImage}
                            alt="New York City Skyline"
                            w="full"
                            h={{ base: "300px", md: "500px" }}
                            objectFit="cover"
                        />
                    </Box>


                    {/* Blog Sections */}
{blogData.sections.map((section, index) => (
    <Box key={index} mb={4} overflow="hidden">
        <Box
            display="flex"
            flexDirection={{
                base: "column",
                md: index % 2 === 0 ? 'row' : 'row-reverse'
            }}
            alignItems="center"
            gap={8}
            flexWrap="wrap"
        >
            {/* Title and Content Section */}
            <Box
                flex={1}
                minW="300px"
                p={{ base: 2, md: 8 }}
                order={0} // Always displayed first
            >
                <Heading
                    as="h2"
                    size="xl"
                    mb={4}
                    color={colorMode === 'dark' ? 'dark_tertiary.bg' : 'light_tertiary.bg'}
                >
                    {section.title}
                </Heading>
                <Text
                    fontSize="lg"
                    lineHeight="tall"
                    textAlign="justify"
                    whiteSpace="pre-line"
                    dangerouslySetInnerHTML={{ __html: section.content }}
                />
            </Box>

            {/* Image/Widget Section */}
            <Box
                flex={1}
                minW="300px"
                overflow="hidden"
                borderRadius="xl"
                order={1} // Always displayed second
            >
                <Box
                    bg={colorMode === 'dark' ? 'dark_bg.black' : 'light_bg.lightgrey'}
                    borderRadius="xl"
                    p={6}
                    boxShadow="md"
                >
                    <script
                        type="text/javascript"
                        dangerouslySetInnerHTML={{
                            __html: `
                                window.onload = function() {
                                    var script = document.createElement('script');
                                    script.src = 'https://widget.getyourguide.com/dist/pa.js';
                                    script.async = true;
                                    document.body.appendChild(script);
                                }
                            `
                        }}
                    />
                    <div
                        dangerouslySetInnerHTML={{
                            __html: `<div 
                                        data-gyg-href="https://widget.getyourguide.com/default/activities.frame" 
                                        data-gyg-locale-code="${blogData.language}"
                                        data-gyg-widget="activities" 
                                        data-gyg-number-of-items="1" 
                                        data-gyg-cmp="${blogData.campagne}" 
                                        data-gyg-partner-id="4NBF07E" 
                                        data-gyg-tour-ids="${section.affiliation.tour_id}">
                                        <span>Powered by<a target="_blank" rel="sponsored" 
                                        href="${section.affiliation.url}">
                                        GetYourGuide</a></span>
                                    </div>`
                        }}
                    />
                </Box>
                <Divider py={4}></Divider>
                <Button 
                    bg={colorMode === 'dark' ? 'dark_secondary.bg' : 'light.bg'}
                    color={colorMode === 'dark' ? 'dark_secondary.color' : 'light_tertiary.bg'}
                    href={`${section.affiliation.url}/?partner_id=4NBF07E&utm_medium=online_publisher&cmp=${blogData.campagne}&mkt_cmp=true`}
                    fontSize="xl"
                    as="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    width="100%"
                    minHeight={"64px"}
                    >
                        {blogData.callToAction}
                </Button>
            </Box>
        </Box>
    </Box>
))}







































































                </VStack>
            </Container>


            {/* Conseils de Voyage */}
            <Box
                bg={colorMode === 'dark' ? 'dark_bg.darkgrey' : 'light_bg.white'}
                mt={{ base: 10, md: 6 }}
                pt={{ base: 10, md: 20 }}
                pb={{ base: 14, md: 24 }}
                px={4}
            >
                <Container maxW="container.xl">
                    <VStack spacing={8} align="stretch">
                        <Heading
                            textAlign="center"
                            size="xl"
                            color={colorMode === 'dark' ? 'dark_tertiary.bg' : 'light_tertiary.bg'}
                            mb={6}
                        >
                            {blogData.travelTips.title}
                        </Heading>

                        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8}>
                            {blogData.travelTips.sections.map((section, index) => (
                                <Box
                                    key={index}
                                    bg={colorMode === 'dark' ? 'dark_bg.black' : 'light_bg.lightgrey'}
                                    p={6}
                                    borderRadius="xl"
                                    boxShadow="md"
                                    transition="all 0.3s ease"
                                    _hover={{
                                        transform: 'translateY(-10px)',
                                        boxShadow: 'xl'
                                    }}
                                >
                                    <Flex align="center" mb={4}>
                                        <Icon
                                            as={section.icon === "FaHotel" ? FaHotel :
                                                section.icon === "FaUtensils" ? FaUtensils :
                                                    FaSubway
                                            }
                                            w={10}
                                            h={10}
                                            color={colorMode === 'dark' ? 'dark.bg' : 'light.bg'}
                                            mr={4}
                                        />
                                        <Heading
                                            as="h3"
                                            size="md"
                                            color={colorMode === 'dark' ? 'gray.400' : 'gray.600'}
                                        >
                                            {section.title}
                                        </Heading>
                                    </Flex>
                                    <VStack
                                        align="start"
                                        spacing={3}
                                        color={colorMode === 'dark' ? 'dark_secondary.color' : 'light_secondary.color'}
                                    >
                                        {section.tips.map((tip, tipIndex) => (
                                            <Flex key={tipIndex} align="center">
                                                <Box
                                                    w="8px"
                                                    h="8px"
                                                    borderRadius="full"
                                                    bg={colorMode === 'dark' ? 'dark_tertiary.bg' : 'light_tertiary.bg'}
                                                    mr={3}
                                                />
                                                <Text fontSize="md">{tip}</Text>
                                            </Flex>
                                        ))}
                                    </VStack>
                                </Box>
                            ))}
                        </SimpleGrid>
                    </VStack>
                </Container>
            </Box>


            {/* GetYourGuide Section */}
            <Box
                pt={{ base: 10, md: 20 }}
                pb={{ base: 14, md: 24 }}
                px={4}
            >
                <Container maxW="container.xl">
                    <VStack spacing={8} align="stretch">
                        <Heading
                            textAlign="center"
                            size="xl"
                            color={colorMode === 'dark' ? 'dark_tertiary.bg' : 'light_tertiary.bg'}
                        >
                            {blogData.getYourGuide.title}
                        </Heading>

                        <Text
                            textAlign="center"
                            fontSize="lg"
                            maxW="800px"
                            mx="auto"
                            color={colorMode === 'dark' ? 'dark_secondary.color' : 'light_secondary.color'}
                            mb={{ base: 2, md: 6 }}
                        >
                            {blogData.getYourGuide.description}
                        </Text>

                        <Box
                            bg={colorMode === 'dark' ? 'dark_bg.black' : 'light_bg.lightgrey'}
                            borderRadius="xl"
                            p={6}
                            boxShadow="md"
                        >
                            <script
                                type="text/javascript"
                                dangerouslySetInnerHTML={{
                                    __html: `
                            window.onload = function() {
                                var script = document.createElement('script');
                                script.src = 'https://widget.getyourguide.com/dist/pa.js';
                                script.async = true;
                                document.body.appendChild(script);
                            }
                        `
                                }}
                            />
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: blogData.getYourGuide.widgetScript
                                }}
                            />
                        </Box>
                    </VStack>
                </Container>
            </Box>


            {/* Conclusion */}
            <Box
                textAlign="center"
                p={6}
                pt={{ base: 10, md: 20 }}
                pb={{ base: 10, md: 16 }}
                bg={colorMode === 'dark' ? 'dark_bg.darkgrey' : 'light_bg.lightgrey'}
                borderRadius="xl"
            >
                <Heading
                    as="h3"
                    size="xl"
                    mb={4}
                    color={colorMode === 'dark' ? 'dark_tertiary.bg' : 'light_tertiary.bg'}
                >
                    {blogData.conclusion.title}
                </Heading>
                <Text
                    fontSize="lg"
                    whiteSpace="pre-line"
                    maxW="1000px"
                    mx="auto"
                    color={colorMode === 'dark' ? 'dark_secondary.color' : 'light_secondary.color'}
                >
                    {blogData.conclusion.content}
                </Text>
                {/* Bouton de partage en haut à droite */}
                <Button
                    aria-label="Partager le blog"
                    position="relative"
                    mt={6}
                    zIndex={10}
                    onClick={handleShare}
                    bg={colorMode === 'dark' ? theme.colors.dark_tertiary.bg : theme.colors.light_tertiary.bg}
                    color={colorMode === 'dark' ? theme.colors.dark_tertiary.color : theme.colors.light_tertiary.color}
                    _hover={{
                        bg: colorMode === 'dark' ? theme.colors.dark.bg : theme.colors.light.bg,
                        transform: 'translateY(-2px)',
                        boxShadow: 'lg'
                    }} variant="full"
                >
                    <Icon as={FaShareAlt} mr={2} />
                    {hasCopied ? 'Lien copié !' : 'Partager'}
                </Button>
            </Box>
        </Box>

    );
};

export default Activites;